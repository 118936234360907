@use "../config/" as *;
.waf-cricketscorecard {
    .matchinfo {
        @extend %p-3;
        &-list {
            @extend %flex-column;
            @extend %gap-2;
        }
        &-item {
            @extend %p-2;
            @extend %half-radius;
            @extend %flex-column;
            @extend %neutral-500-bg-1;
            .label {
                @extend %font-12-pb;
                @extend %mb-2;
            }
            .info {
                @extend %font-12-pr;
            }
        }
    }
}
@include mq(col-md) {
    .waf-cricketscorecard {
        .matchinfo {
            &-list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .label,
                .info {
                    font-size: 1.4rem;
                }
            }
            &-item {
                &:nth-child(3) {
                    grid-column: span 2;
                }
            }
        }
    }
}