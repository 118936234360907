@use "../config/" as *;
.webview {
    @extend %neutral-100-bg;
    .waf-cricketscorecard {
        .card {
            &-section {
                > .card-item {
                    &::before {
                        @extend %h-100;
                        @include background(var(--secondary-900), "matchcenter/mobile/masthead-webview.png", top/cover);
                    }
                }
            }
            &-item {
                @extend %pb-3;
                &.live {
                    .status {
                        @extend %success-900-bg;
                        @extend %flex-n-c;
                        &::before {
                            content: "";
                            width: 1.5rem;
                            aspect-ratio: 1 / 1;
                            @include background(null, "svg/live-blinker.svg", center/contain no-repeat);
                            @extend %mr-1;
                        }
                    }
                    .match-status {
                        &::before {
                            content: unset;
                        }
                    }
                }
                &.recent {
                    .status {
                        @extend %accent-900-bg;
                    }
                }
                &.upcoming {
                    .status {
                        @extend %tertiary-600-bg;
                    }
                }
                &.live,
                &.recent,
                &.upcoming {
                    .match-status {
                        background-color: unset;
                    }
                }
            }
        }
        .match {
            &-status {
                isolation: isolate;
                background-color: unset;
                width: unset;
                @extend %flex-c-c;
                @extend %p-0;
                .status {
                    width: max-content;
                    @extend %font-12-qb;
                    @extend %neutral-600-bg;
                    @extend %p-1-3;
                }
                &:after {
                    content: "";
                    height: .1rem;
                    pointer-events: none;
                    z-index: var(--z-patterns);
                    @extend %pos-center;
                    @extend %w-100;
                    @extend %pure-white-900-bg-2;
                }
            }
            &-number {
                @extend %flex-n-c;
                @extend %gap-1;
            }
            &-number,
            &-time {
                span {
                    @extend %font-12-qb;
                }
            }
            &-time {
                @extend %flex-n-c;
                @extend %gap-2;
                .time {
                    @extend %flex;
                }
            }
        }
        .team {
            justify-content: space-between;
            @extend %w-100;
            .name {
                @extend %font-14-qb;
            }
            &-name {
                @extend %mt-0;
                @extend %uppercase;
            }
            &-venue {
                text-wrap: balance;
                .text {
                    @extend %font-12-qb;
                }
            }
            &-info {
                width: auto;
                max-width: 60%;
                @extend %gap-2;
                @extend %flex-n-c;
                @extend %font-12-qb;
            }
            &-b {
                flex-direction: row;
            }
            &-img {
                @extend %p-1;
            }
            &-wrap {
                border-color: clr(pure-white-900, 2);
                @extend %mt-8;
                @extend %mb-0;
                @extend %gap-3;
                @extend %flex-wrap;
            }
            &-divider {
                @extend %d-none;
            }
            &-status {
                background: unset;
                @extend %py-4;
                .text {
                    text-wrap: balance;
                    line-height: 2rem;
                    @extend %font-12-tm;
                }
            }
            &-data-wrap {
                @extend %flex-n-c;
            }
            &-extra {
                line-height: 1;
            }
            &-score {
                @extend %flex-column-n-fe;
                @extend %px-2;
                .score {
                    @extend %font-16-qb;
                }
                .overs {
                    @extend %font-10-qr;
                    @extend %primary-50;
                }
                .score.no-score {
                    @extend %accent-100;
                }
                &:not(:last-child) {
                    border-right: .05rem solid clr(pure-white-900, 2);
                }
            }
            &-current,
            &-won {
                .team-score:last-child {
                    .score {
                        @extend %accent-100;
                    }
                }
            }
            &-b {
                .team-name {
                    @extend %text-left;
                }
            }
        }
        .end-match-footer {
            border-top: .1rem solid clr(pure-white-900, 2);
            @extend %pt-4;
            .text {
                @extend %font-12-tr;
            }
            .player-name {
                @extend %font-12-tsb;
                @extend %accent-100;
            }
        }
        .innings-info {
            .head {
                @extend %primary-50-bg;
                @extend %secondary-900;
            }
            .text,
            .player-name .name {
                @extend %uppercase;
            }
            .player {
                &-details {
                    border-radius: 0;
                    .text {
                        @extend %font-14-qb;
                    }
                }
                &-name {
                    flex-shrink: 0;
                    .name {
                        @extend %w-100;
                        @extend %font-12-qr;
                        &:last-child {
                            font-weight: 700;
                        }
                    }
                }
                &-data {
                    background-color: unset;
                    border: 0 solid var(--primary-1000);
                    border-width: 0 0.05rem 0.05rem;
                    .runs {
                        @extend %ml-0;
                    }
                    .runs,
                    .score {
                        @extend %font-14-qb;
                        @extend %accent-100;
                    }
                }
                &-info {
                    &-content {
                        @extend %w-100;
                    }
                    &:not(:last-child) {
                        &::after {
                            width: 0.05rem;
                            @extend %neutral-1000-bg;
                        }
                    }
                }
                &-score {
                    @extend %flex-n-fe;
                    @extend %gap-1;
                }
            }
        }
        .wicket-status .wicket-data,
        .card-wrapper {
            border-radius: 0;
        }
        .wicket-status {
            .wicket-data {
                @extend %primary-100-bg;
                .text {
                    @extend %primary-900;
                    @extend %font-14-qb;
                    &.over {
                        @extend %primary-700;
                        @extend %font-10-qr;
                    }
                }
            }
            .card-wrapper {
                .player-name {
                    @extend %font-12-qb;
                }
            }
            .swiper {
                @extend %px-10;
                &-button-disabled {
                    opacity: 1;
                }
            }
        }
        .matchinfo {
            background-color: unset;
            @extend %p-0;
            &-item {
                border-radius: 0;
                @extend %pure-white-900-bg;
                @extend %p-3;
                .label,
                .info {
                    @extend %neutral-1200;
                }
                .label {
                    @extend %font-14-qb;
                    @extend %capitalize;
                }
                .info,
                .info-wrap {
                    @extend %font-14-tm;
                    span {
                        font: inherit;
                    }
                }
            }
        }
        .scorecard {
            @extend %pure-white-900-bg;
            .head-data {
                .team {
                    &-score-detail {
                        @extend %p-4-2;
                        @extend %pure-white-900-bg;
                    }
                    &-logo {
                        @extend %neutral-5-bg;
                    }
                    &-name {
                        @extend %w-70;
                        @extend %font-16-qb;
                    }
                    &-score-data {
                        .text {
                            @extend %neutral-1300;
                        }
                    }
                }
                .text {
                    @extend %font-12-qm;
                }
            }
            .table {
                .name {
                    @extend %font-12-qb;
                }
                .info {
                    @extend %mt-1;
                    .text {
                        @extend %font-10-tm;
                    }
                }
                .text {
                    @extend %neutral-800;
                }
                &-responsive {
                    border-radius: 0;
                    .table-head {
                        .text {
                            @extend %neutral-800;
                            @extend %font-12-qb;
                        }
                        .label {
                            .text {
                                @extend %capitalize;
                            }
                        }
                    }
                }
                &-head {
                    background: var(--primary-1100);
                }
                &-data {
                    .name {
                        @extend %uppercase;
                    }
                    .text {
                        font-weight: 700;
                    }
                }
                &-body {
                    .text {
                        @extend %font-12-qm;
                    }
                    .table-row {
                        border-color: var(--neutral-1000);
                    }
                }
            }
            .impact-player-container,
            .extras-scorecard {
                @extend %mb-0;
                @extend %p-3-2;
                @extend %secondary-1000-bg;
            }
            .extras-scorecard {
                align-items: center;
                padding-bottom: var(--space-5);
                @extend %flex-wrap;
                @extend %gap-1;
                .extra-info,
                .total {
                    @extend %neutral-1300;
                    @extend %font-12-tr;
                }
                .label {
                    @extend %font-12-qb;
                    @extend %neutral-800;
                }
                .total {
                    margin-left: unset;
                    font-weight: 400;
                }
            }
            .total-runs {
                @extend %p-3-2;
                @extend %mb-0;
                .label,
                .extra-info {
                    @extend %font-14-qb;
                }
            }
            .wickets {
                .overs {
                    .text {
                        @extend %capitalize;
                    }
                }
                .player-data {
                    align-items: center;
                    .text,
                    .name {
                        @extend %primary-900;
                    }
                    .info {
                        @extend %mt-0;
                    }
                    .text {
                        @extend %font-12;
                    }
                }
            }
            .player-out .table-data-wrapper:after{
                @extend %error-900-bg;
            }
        }
        .impact-player {
            &-legends {
                @extend %p-3-2;
                @extend %secondary-900-bg;
                .label {
                    @extend %font-14-qb;
                    &-txt {
                        @extend %font-10-qr;
                        @extend %primary-50;
                    }
                }
            }
            &-label {
                justify-content: space-between;
                .out::after{
                @extend %error-900-bg;

                }
            }
            &-name {
                @extend %neutral-1300;
                @extend %font-12-tr;
                span {
                    color: inherit;
                    font: inherit;
                }
            }
            &-container {
                .title {
                    @extend %mb-1;
                    @extend %font-12-qb;
                }
            }
        }
        .tab-container-wrap {
            &.teams {
                .table {
                    &-title {
                        @extend %capitalize;
                        @extend %primary-100-bg;
                        @extend %secondary-900;
                        @extend %font-14-qb;
                        @extend %px-2;
                    }
                    &-body {
                        @extend %pure-white-900-bg;
                        .table-row:not(:last-child) {
                            border-bottom: .1rem solid var(--neutral-1000);
                        }
                    }
                    &-row {
                        border-radius: 0;
                        background-color: unset;
                        @extend %my-0;
                    }
                    &-data {
                        @extend %p-4-2;
                    }
                    &-responsive {
                        .table {
                            padding-inline: 0;
                        }
                    }
                    &-left {
                        .text {
                            @extend %neutral-1300;
                            @extend %font-12-tr;
                        }
                    }
                }
                .player-name {
                    .name {
                        @extend %font-14-qb;
                        @extend %uppercase;
                        @extend %primary-900;
                    }
                }
            }
        }
        .manhattan-section {
            .team-a::after {
                background: linear-gradient(0deg, var(--secondary-800) 0%, var(--primary-700) 100%);
            }
            .team-b::after {
                @extend %secondary-1100-bg;
            }
            .wicket-text-label {
                @extend %tertiary-1100-bg;
                @extend %neutral-800;
            }
            .score-line {
                .score-label {
                    left: -3rem;
                }
                .score-label,
                .line-wrap .lable {
                    @extend %font-12-qb;
                }
            }
            .graph-item .graph-value {
                height: 2.7rem;
                @extend %font-12-qb;
            }
            .over-label-text {
                @extend %font-14-qm;
            }
        }
        .spider-section {
            .controls-item {
                .runs,
                .number {
                    @extend %font-12-qb;
                }
                &.item1 {
                    border-color: clr(spider-webview-1s);
                    .number {
                        @extend %spider-webview-1s-bg;
                    }
                }
                &.item2 {
                    border-color: clr(spider-webview-2s);
                    .number {
                        @extend %spider-webview-2s-bg;
                    }
                }
                &.item3 {
                    border-color: clr(spider-webview-3s);
                    .number {
                        @extend %spider-webview-3s-bg;
                    }
                }
                &.item4 {
                    border-color: clr(spider-webview-4s);
                    .number {
                        @extend %spider-webview-4s-bg;
                    }
                }
                &.item5 {
                    border-color: clr(spider-webview-5s);
                    .number {
                        @extend %spider-webview-5s-bg;
                    }
                }
            }
            .zone-listing {
                .data {
                    @extend %primary-900;
                    @extend %font-12-qb;
                }
            }
            .svg-graph {
                @include bgImg("matchcenter/spider-graph-webview.svg");
            }
        }
    }
    .tab-title {
        @extend %font-12-qb;
    }
    .tabs {
        @extend %pure-white-900-bg;
        .tab-name {
            @extend %relative;
            @extend %tertiary-1000;
            @extend %uppercase;
            @extend %font-12-qr;
            span {
                font: inherit;
            }
            &:not(:first-child) {
                &::before {
                    content: "";
                    width: 0.05rem;
                    @include position-combo("y-center", null, 0);
                    @extend %h-50;
                    @extend %tertiary-1000-bg;
                }
            }
        }
        .tab-active,
        .active {
            font-weight: 700;
        }
        &.secondary-tabs {
            gap: unset;
            .tab-name {
                border: none;
                border-bottom: 0.05rem solid var(--tertiary-1000);
                border-radius: 0;
                @extend %font-12;
            }
            .tab-active,
            .active {
                border-color: var(--primary-700);
                border-width: 0.2rem;
                @extend %primary-700;
            }
        }
        &:not(.secondary-tabs) {
            .tab-active,
            .active {
                @extend %accent-100-bg;
                @extend %pure-white-900;
                &::before {
                    content: unset;
                }
                + .tab-name {
                    &::before {
                        content: unset;
                    }
                }
            }
        }
    }
    .tab-container {
        @extend %px-3;
    }
    .primary-tabs {
        @extend %mt-3;
        @extend %mb-4;
        .tab-name {
            @extend %px-6;
        }
    }
    .live-match-footer {
        .footer-wrap {
            @extend %d-block;
            @extend %pt-2;
        }
    }
    .score-data,
    .tabs,
    .loadmore,
    .tab-container-wrap {
        border-radius: 0;
    }
    .ball-status .swiper-slide::after {
        content: unset;
    }
    .score-data,
    .wicket-data {
        .swiper-button-next,
        .swiper-button-prev {
            &.swiper-button-disabled {
                &::after {
                    opacity: .5;
                }
            }
        }
    }
    .score-data {
        @extend %mx-3-neg;
        .tabs-container-wrap {
            @extend %pt-3;
        }
        .body {
            background-color: unset;
        }
        .swiper-button-prev,
        .swiper-button-next {
            z-index: var(--z-swiper-button);
            border-color: var(--primary-1000);
            @extend %secondary-900-bg;
        }
        .tabs {
            overflow: unset;
            align-items: center;
            .tab-name {
                min-width: unset;
            }
        }
        .over-list {
            @extend %pb-2;
        }
        .over-item {
            border-radius: 0;
            background-color: unset;
            border: 0.05rem solid var(--primary-1000);
            @extend %pure-white-900;
            span {
                @extend %font-12-qb;
            }
            &.six,
            &.four,
            &.wicket {
                border: none;
            }
            &.six,
            &.four {
                @extend %tertiary-600-bg;
                @extend %neutral-800;
            }
            &.wicket {
                @extend %accent-100-bg;
            }
        }
        .card-wrapper {
            > .text {
                @extend %font-12-tr;
            }
        }
    }
    .commentary {
        .highlight-title {
            @extend %font-16-qb;
        }
        .sub-title,
        .commentary-title {
            @extend %font-14-qb;
        }
        .para-text,
        p {
            line-height: 2rem;
            @extend %font-12-tr;
        }
        &-title {
            @extend %uppercase;
        }
        &-text {
            line-height: 2rem;
        }
        .over {
            &-info {
                .head {
                    @extend %primary-1100-bg;
                    @extend %font-12-qb;
                }
                .body {
                    .score {
                        border-radius: 0;
                        border: none;
                        height: 4rem;
                        min-width: 4rem;
                        @extend %font-14-qb;
                        @extend %neutral-1100-bg;
                        span {
                            font: inherit;
                        }
                    }
                    .four,
                    .six {
                        .score {
                            @extend %tertiary-600-bg;
                        }
                    }
                }
                .over-item {
                    border-radius: 0;
                    @extend %pure-white-900-bg;
                }
                &-wrapper {
                    @extend %mx-2-neg;
                    @extend %neutral-100-bg;
                }
            }
            &-text {
                @extend %font-12-qb;
                @extend %secondary-900;
                &:not(:first-child) {
                    border-color: var(--secondary-900);
                }
            }
            &-count {
                @extend %font-12-qr;
            }
            &-commentary {
                border-color: var(--neutral-1000);
            }
        }
    }
    .loadmore {
        @extend %accent-100-bg;
        @extend %pure-white-900;
        @extend %font-16-qb;
        &-wrap {
            width: max-content;
            @extend %my-0;
            @extend %py-3;
        }
    }
    .graph .tabs .tab-name {
        @extend %capitalize;
    }
    .graphs-tab .tab-name,
    .tab-legends .legend-item {
        min-height: 3.2rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .team-img {
            height: 3.2rem;
            width: 3.2rem;
            border-color: var(--neutral-5);
            @extend %neutral-5-bg;
            @extend %ml-0;
            @extend %p-1;
        }
    }
    .graphs-tab {
        .tabs {
            @extend %gap-2;
        }
        .tab-name {
            .inning-score {
                @extend %font-12-qr;
            }
            &.active {
                background-color: clr(secondary-1100);
                &:first-child {
                    background: linear-gradient(0deg, var(--secondary-800) 0%, var(--primary-700) 100%);
                }
                &:last-child {
                    color: var(--neutral-800);
                }
            }
        }
    }
    .tab-legends {
        @extend %py-4;
        .legend-item {
            border: none;
            // min-height: 3.2rem;
            // border-top-right-radius: 0;
            // border-bottom-right-radius: 0;
            @extend %pr-4;
            .name {
                @extend %font-12-tsb;
            }
            &.team-a {
                background: linear-gradient(0deg, var(--secondary-800) 0%, var(--primary-700) 100%);
            }
            &.team-b {
                @extend %secondary-1100-bg;
                .name {
                    @extend %neutral-800;
                }
            }
        }
    }
    .innings-progression-section .score-line {
        .score-label {
            bottom: 3rem;
            left: -1.2rem;
        }
        .over-label-text {
            @extend %font-12-qm;
        }
    }
    &.ios .waf-cricketscorecard .manhattan-section .graph-item {
        padding-bottom: 3rem;
        .graph-value {
            height: 3rem;
        }
    }
}