@use "../config/" as *;
.waf-cricketscorecard {
    .share-page {
        @extend %d-none;
    }
    overflow: hidden;
    padding: 0;
    .layout-wrapper {
        max-width: unset;
    }
    .waf-head {
        @extend %d-none;
    }
    .player-a,
    .player-b {
        @extend %d-none;
    }
    .card {
        &-section {
            > .card-item {
                &::before {
                    content: "";
                    height: calc(100% - 10rem);
                    @include background(var(--primary-700), "matchcenter/mobile/masthead.jpg", top/cover);
                    @extend %pos-tl;
                    @extend %w-100;
                }
            }
        }
        &-item {
            @extend %p-6-3;
            @extend %primary-50;
            @extend %relative;
            &.live {
                .match-status {
                    &::before {
                        content: "";
                        display: inline-block;
                        width: 1.5rem;
                        aspect-ratio: 1 / 1;
                        background: url("/static-assets/images/svg/live-blinker.svg?v=#{$image-version}") center center no-repeat;
                        background-size: contain;
                        @extend %mr-2;
                    }
                    @extend %accent-900-bg;
                }
            }
            &.recent {
                .match-status {
                    @extend %neutral-600-bg;
                }
            }
            &.upcoming {
                .match-status {
                    @extend %neutral-50-bg;
                    @extend %neutral-900;
                }
            }
            &.super-over {
                .team-score {
                    @extend %flex-n-c;
                    @extend %gap-1;
                    &:first-child:not(:last-child) {
                        opacity: 0.5;
                    }
                    .score {
                        @extend %font-14;
                    }
                    .overs {
                        @extend %font-12;
                    }
                }
            }
        }
        &-head {
            padding-bottom: 2rem;
        }
        &-wrap {
            padding-block: 4rem 0rem;
            @extend %relative;
        }
    }
    .match {
        &-info {
            @extend %flex-c-n;
            @extend %flex-wrap;
            @extend %gap-2;
        }
        &-title {
            @extend %d-none;
        }
        &-status {
            position: absolute;
            inset-inline: 0;
            top: 0;
            width: max-content;
            @extend %mx-auto;
            @extend %p-1-2;
            @extend %half-radius;
            @extend %neutral-600-bg;
            @extend %neutral-50;
            @extend %font-14-pb;
            @extend %uppercase;
        }
        &-time {
            font-weight: 700;
            .time {
                @extend %d-none;
            }
        }
        &-number {
            font-weight: 700
        }
    }
    .end-match-footer {
        @extend %text-center;
        @extend %w-100;
        @extend %py-2;
        .text {
            font-size: 1.2rem
        }
        .player-name {
            font-weight: 700
        }
    }
    .team {
        width: calc(50% - 2rem);
        @extend %flex;
        @extend %gap-2;
        &-venue {
            position: absolute;
            top: 5rem;
            left: 0;
            right: 0;
            @extend %font-14-pm;
            @extend %text-center;
            @extend %py-3;
            .text {
                font: inherit
            }
        }
        &-status {
            line-height: 1.2;
            background: linear-gradient(90deg, transparent 0%, clr(primary-900, 5) 8.2%, clr(primary-900) 50%, clr(primary-900, 5) 93.17%, transparent 100%);
            @extend %flex-column-c-c;
            @extend %py-2;
            @extend %text-center;
            @extend %w-100;
            .text {
                @extend %font-14;
            }
        }
        &-b {
            flex-direction: row-reverse;
            .team-name {
                justify-content: flex-end;
                @extend %text-right;
            }
            .team-score {
                @extend %text-right;
            }
        }
        &-divider {
            @extend %mx-2;
            &::before {
                content: "";
                position: absolute;
                inset: 0;
                width: .1rem;
                height: 80%;
                margin: auto;
                @extend %neutral-50-bg-1;
            }
            .text-vs {
                width: 2rem;
                line-height: 1.8rem;
                @extend %flex-c-c;
                @extend %font-14-pb;
                @extend %accent-900-bg;
                @extend %quater-radius;
            }
        }
        &-name {
            @extend %mt-2;
            @extend %flex-fs-n;
        }
        &-score {
            .score {
                @extend %font-18-pb;
                &.no-score {
                    font-size: 1.4rem;
                    @extend %tertiary-600;
                }
            }
            .no-score {
                height: 5rem;
                @extend %flex-n-c;
            }
            .overs {
                @extend %font-12-pm;
            }
            .run-rate {
                @extend %d-none;
            }
        }
        &-info {
            width: 5rem;
        }
        &-current,
        &-won {
            .team-score:last-child {
                .score {
                    @extend %tertiary-600;
                }
            }
        }
        .shortname {
            @extend %d-none;
        }
        .name {
            line-height: 1.2;
            @extend %font-12-pb;
        }
        &-wrap {
            border-block: .1rem solid clr(neutral-50, 4);
            @extend %my-4;
            @extend %py-4;
            @extend %flex-sb-c;
            @extend %relative;
        }
        &-img {
            width: 5rem;
            height: 5rem;
            flex-shrink: 0;
            @extend %hidden;
            @extend %circle-radius;
            @extend %neutral-50-bg;
            @extend %p-2;
            img {
                object-fit: contain;
                object-position: center;
                @extend %w-100;
                @extend %h-100;
            }
        }
    }
    .innings-info {
        .head {
            @extend %p-1-3;
            @extend %secondary-900-bg;
            .text {
                margin-bottom: 0;
            }
        }
        .player {
            &-data {
                @extend %flex-c-n;
                @extend %w-100;
                @extend %primary-900-bg;
                .runs {
                    @extend %tertiary-600;
                    @extend %font-14-pb;
                    @extend %ml-1;
                }
                .score {
                    @extend %tertiary-600;
                    @extend %font-14-pb;
                }
                .name {
                    @extend %capitalize;
                    @extend %font-14-pm;
                    @extend %neutral-50;
                }
                .fname {
                    @extend %mr-1;
                }
            }
            &-info {
                width: 50%;
                flex-shrink: 0;
                @extend %relative;
                @extend %flex-n-c;
                &:not(:last-child) {
                    position: relative;
                    &::after {
                        content: "";
                        width: 0.1rem;
                        height: 70%;
                        right: 0;
                        @extend %neutral-50-bg-2;
                        @extend %pos-y-center;
                    }
                }
                &:first-child {
                    @extend %pl-1;
                    .player-info-content {
                        @extend %pl-1;
                    }
                    &::before {
                        content: "";
                        width: 2rem;
                        flex-shrink: 0;
                        opacity: 0.7;
                        @extend %pl-1;
                        @extend %success-900;
                        @include icon(chevron-right, 10);
                    }
                }
            }
            &-team {
                @extend %d-none;
            }
            &-details {
                @extend %full-radius;
                @extend %hidden;
                @extend %mb-4;
                .text {
                    display: inline-block;
                    @extend %font-14-pb;
                    @extend %mb-2;
                }
            }
            &-info-content {
                @extend %p-4-2;
                @extend %gap-1;
                @extend %flex-sb-c;
            }
            &-name {
                flex-wrap: wrap;
                @extend %flex;
            }
            &-thumbnail {
                @extend %d-none;
            }
            &-score {
                flex-shrink: 0;
            }
        }
    }
    .countdown-venue-wrapper {
        @extend %d-none;
    }
    .ball-status {
        .swiper-slide {
            position: relative;
            &::after {
                content: "";
                width: .1rem;
                height: 70%;
                position: absolute;
                right: 0;
                top: 15%;
                @extend %neutral-50-bg-4;
            }
        }
    }
    .wicket-status {
        .card-wrapper {
            @extend %flex-n-c;
            @extend %neutral-50-bg;
            @extend %neutral-800;
            @extend %pb-0;
            @extend %my-3;
            @extend %pr-2;
            @extend %full-radius;
            .player-name {
                @extend %font-12-pb;
            }
        }
        .swiper {
            @extend %px-2;
            &-slide {
                width: max-content;
                @extend %p-0;
            }
            &-button-disabled {
                opacity: 0;
            }
        }
        .wicket-data {
            @extend %p-2-3;
            @extend %neutral-50;
            @extend %primary-600-bg;
            @extend %full-radius;
            @extend %mr-2;
            .text {
                font-weight: 700;
            }
            .over {
                font-weight: 400;
            }
        }
    }
}
.no-data {
    @extend %text-center;
    @extend %p-2;
    @extend %font-14-pb;
}
.score-data {
    border-radius: var(--half-radius);
    @extend %hidden;
    .tabs {
        border-radius: var(--half-radius) var(--half-radius) 0 0;
    }
    .body {
        @extend %primary-900-bg;
    }
    .swiper-pagination {
        @extend %d-none;
    }
    .swiper-button-prev {
        top: 0;
        left: 0;
        height: 100%;
        transform: unset;
        border-right: .1rem solid clr(neutral-50, 5);
        @extend %primary-900-bg;
        &::after {
            @extend %neutral-50;
        }
    }
    .swiper-button-next {
        border-left: .1rem solid clr(neutral-50, 5);
        top: 0;
        right: 0;
        height: 100%;
        transform: unset;
        @extend %primary-900-bg;
        &::after {
            @extend %neutral-50;
        }
    }
    .swiper-button-disabled {
        opacity: 1;
        background-color: transparent
    }
    .card {
        &-list {
            @extend %flex;
        }
        &-item {
            padding: 0;
            background: unset;
            padding-inline: 4rem;
        }
        &-wrapper {
            max-width: max-content;
            @extend %mx-auto;
            @extend %pb-2;
            .over-label {
                @extend %text-center;
            }
            .text {
                @extend %font-12-pm;
                @extend %text-center;
            }
        }
    }
    .over {
        &-list {
            overflow: auto;
            @extend %flex;
            @extend %gap-2;
            @extend %pb-1;
            @extend %pt-3;
            @extend %px-2;
        }
        &-item {
            flex-shrink: 0;
            flex-direction: row-reverse;
            @extend %flex;
            @extend %neutral-800;
            @extend %neutral-50-bg;
            @extend %p-1-2;
            @extend %relative;
            @extend %half-radius;
            .text,
            .extra-text {
                @extend %font-14-pm;
            }
            &.wicket {
                @extend %accent-900-bg;
            }
            // &.six {
            //     @extend %tertiary-600-bg;
            // }
            &.four,
            &.six {
                @extend %primary-600-bg;
                @extend %neutral-50;
            }
            &.wicket {
                @extend %neutral-50;
                @extend %primary-600-bg;
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .waf-cricketscorecard {
        .innings-info {
            .player-data {
                .lname {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 6.5rem;
                }
                .name,
                .runs,
                .score {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-cricketscorecard {
        .tab-container {
            padding: 0;
        }
        .table-left {
            background-color: transparent;
        }
        .card {
            &-section {
                > .card-item {
                    &::before {
                        @include background(var(--primary-700), "matchcenter/masthead.jpg", top/cover);
                    }
                }
            }
            &-wrap {
                padding-top: 5rem
            }
            &-footer {
                margin-bottom: var(--space-4)
            }
        }
        .innings-info {
            display: flex;
            gap: var(--space-4);
            .head {
                padding-inline: var(--space-4);
            }
            .player {
                &-details {
                    flex: 1;
                }
                &-data {
                    flex-direction: column;
                }
                &-info {
                    padding-inline: var(--space-4);
                    width: 100%;
                    &:not(:last-child)::after {
                        width: 90%;
                        height: 0.1rem;
                        bottom: 0;
                        left: 5%;
                        top: unset;
                        transform: unset;
                    }
                    &-content {
                        width: max-content
                    }
                }
            }
        }
        .team {
            gap: 2rem;
            align-items: center;
            width: 50%;
            .score {
                font-size: 3.2rem;
                &.no-score {
                    font-size: 3rem;
                }
            }
            .no-score {
                height: 10rem;
            }
            .overs {
                font-size: 1.6rem;
            }
            .name {
                font-size: 1.8rem
            }
            &-a {
                .team-info {
                    flex-direction: row-reverse;
                }
            }
            &-b {
                .team-name {
                    text-align: left;
                }
            }
            &-info {
                width: unset;
                display: flex;
                align-items: center;
                gap: var(--space-4);
            }
            &-name {
                max-width: 25rem;
                text-align: right;
            }
            &-img {
                width: 10rem;
                height: 10rem;
            }
        }
        .card {
            &-wrap {
                padding-block: 4rem 0rem;
            }
        }
        .match-team-status {
            bottom: 4rem;
            .text {
                font-size: 1.8rem;
                font-weight: 700;
            }
        }
        .match-player-winner {
            .text {
                font-size: 1.8rem
            }
        }
        .score-data {
            .card-item {
                background: unset;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-cricketscorecard {
        .card-item {
            padding-inline: calc(var(--container-white-space) + 5%);
            .card-item {
                padding: 0;
            }
        }
    }
}