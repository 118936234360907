@use "../config" as *;
%icon {
    content: "";
    font: 1.4rem $font-icon;
    right: var(--space-3);
    @extend %circle-radius;
    @extend %flex-c-c;
    @extend %neutral-50;
}
%player-in {
    flex-shrink: 0;
    @include icon(arrow-up, 12);
    @extend %success-900-bg;
}
%player-out {
    @include icon(arrow-down, 12);
    flex-shrink: 0;
    @extend %accent-900-bg;
}
%player-sub {
    flex-shrink: 0;
    @include icon(refresh, 12);
    @extend %neutral-800;
    @extend %warning-900-bg;
}
.waf-cricketscorecard {
    .teams {
        .overseas {
            @extend %relative;
            &::before {
                content: "";
                @include position(1rem, 1rem);
                @include icon(overseas, 10);
                @extend %circle-radius;
                @extend %flex-c-c;
                @extend %primary-900;
            }
        }
        .player-in,
        .player-out,
        .player-sub {
            position: relative;
            &::after {
                position: absolute;
                right: 1.5rem;
                width: 2.2rem;
                height: 2.2rem;
                @extend %circle-radius;
                @extend %flex-c-c;
                @extend %neutral-50;
            }
        }
        .player-in::after {
            @extend %player-in;
        }
        .player-out::after {
            @extend %player-out;
        }
        .player-sub::after {
            @extend %player-sub;
        }
    }
}
.waf-cricketscorecard {
    .impact-player-legends {
        @extend %neutral-50;
        @extend %w-100;
        @extend %primary-900-bg;
        @extend %p-2;
        .label {
            @extend %mb-2;
        }
    }
    .impact-player-label {
        @extend %gap-1;
        @extend %flex-n-c;
        @extend %flex-wrap;
        .label {
            @extend %neutral-50;
            @extend %font-14-pb;
            @extend %mb-2;
        }
        .label-txt {
            width: max-content;
            @extend %capitalize;
            @extend %neutral-50;
            @extend %font-12-pr;
            @extend %gap-1;
            @extend %flex-n-c;
        }
        .sub::after,
        .in::after,
        .out::after {
            width: 1.8rem;
            height: 1.8rem;
            flex-shrink: 0;
            @extend %flex-c-c;
            @extend %icon;
        }
        .sub::after {
            @extend %player-sub;
        }
        .in::after {
            @extend %player-in;
        }
        .out::after {
            @extend %player-out;
        }
    }
    .waf-select-box {
        @extend %d-none;
    }
    .scorecard {
        .tab-section {
            @extend %p-2;
        }
        .tab-head {
            .tab-section {
                @extend %mb-3;
            }
            .waf-select-box {
                min-width: 28rem;
            }
        }
        .head-data {
            .team {
                &-score-detail {
                    @extend %flex-sb-c;
                    @extend %p-2-3;
                    @extend %neutral-500-bg-1;
                }
                &-score-info {
                    @extend %flex-n-c;
                }
                &-name {
                    margin: 0;
                    @extend %font-12-pb;
                    @extend %neutral-800;
                    .name {
                        font: inherit;
                    }
                }
                &-logo {
                    width: 5rem;
                    aspect-ratio: 1;
                    flex-shrink: 0;
                    filter: drop-shadow(0 0 0.2rem var(--neutral-50));
                    @extend %mr-2;
                    @extend %neutral-50-bg;
                    @extend %circle-radius;
                }
                &-img {
                    width: 100%;
                    height: 100%;
                    @extend %p-1;
                }
                &-score-data {
                    flex-shrink: 0;
                    @extend %text-right;
                }
            }
            .text {
                @extend %neutral-800;
                @extend %font-12-pm;
            }
            .shortname,
            .team-role {
                @extend %d-none;
            }
            .runs,
            .over-runrate {
                @extend %font-12-pr;
            }
            .over-runrate {
                display: block;
            }
        }
        .table-responsive {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            .table-head .text {
                @extend %font-14-pb;
                @extend %neutral-50;
            }
            .table-data {
                @extend %p-2;
            }
        }
        .table {
            margin: 0;
            width: 100%;
            border-radius: 0;
            border: none;
            display: table;
            .text {
                @extend %neutral-900;
            }
            &-head {
                height: 4rem;
                background: linear-gradient(91.83deg, clr(primary-800) 0%, clr(primary-700) 100%);
                border-radius: 0;
                @extend %flex-n-c;
                @extend %secondary-900-bg;
                .table-row {
                    border: 0;
                }
            }
            &-row {
                height: max-content;
                border-bottom: .1rem solid clr(neutral-800, 5);
                @extend %flex-sb-s;
                @extend %w-100;
            }
            &-data {
                width: 8%;
                flex-shrink: 0;
                min-height: 4.2rem;
                height: auto;
                @extend %text-center;
                @extend %flex-column-c-c;
                &.label {
                    width: 56%;
                    @extend %text-left;
                    @extend %px-2;
                    @extend %flex-column-c-fs;
                    .name {
                        @extend %m-0;
                    }
                }
            }
            &-body {
                .text {
                    @extend %font-12-pr;
                }
                .table-row {
                    border-bottom: .1rem solid clr(neutral-800, 4)
                }
                .table-row:nth-child(even) .table-data {
                    @extend %transparent-bg;
                }
                .table-row:nth-child(even) {
                    @extend %transparent-bg;
                }
            }
            .strike-rate,
            .economy-ball {
                width: 12%;
            }
            .name {
                @extend %font-12-pb;
            }
            .info .text {
                text-transform: unset;
                @extend %d-block;
                @extend %font-12-pr;
            }
            .player-thumbnail {
                @extend %d-none;
            }
        }
        .batter {
            .info {
                @extend %mt-2;
            }
            .table-responsive {
                .table-head .label {
                    &::before {
                        // @include icon(all-rounder, 18);
                    }
                }
            }
        }
        .wickets {
            .table-head .label {
                &::before {
                    // @include icon(wicket, 18);
                }
            }
            .name {
                @extend %pl-3;
            }
            .player-data {
                display: flex;
                flex-direction: row-reverse;
            }
            .table-body {
                .table-data {
                    height: 5rem;
                }
            }
            .table-data {
                width: 20%;
                &.label {
                    width: 80%;
                }
            }
        }
        .bowler {
            .table-responsive {
                .table-head .label {
                    &::before {
                        // @include icon(bowler, 20);
                    }
                }
                .table-data {
                    @extend %px-0;
                }
            }
            .table {
                &-data {
                    width: 8%;
                    &.label {
                        width: 41%;
                        @extend %px-2;
                    }
                }
            }
            .economy-rate {
                width: 11%;
            }
        }
        .extras-scorecard {
            @extend %px-2;
            @extend %mb-3;
            @extend %flex;
            @extend %gap-2;
            .total {
                width: max-content;
                flex-shrink: 0;
                @extend %ml-auto;
                @extend %font-12-pb;
            }
            span {
                @extend %font-12-pr;
                @extend %neutral-800;
            }
        }
        .total-runs {
            @extend %px-2;
            @extend %flex-sb-c;
            @extend %mb-3;
            span {
                @extend %font-14-pb;
            }
        }
        .player-in,
        .player-out {
            .table-data-wrapper {
                @extend %flex-n-c;
                &:after {
                    width: 2.4rem;
                    height: 2.4rem;
                    flex-shrink: 0;
                    @extend %ml-2;
                    @extend %icon;
                }
            }
        }
        .player-in {
            .table-data-wrapper {
                &:after {
                    @extend %player-in;
                }
            }
        }
        .player-out {
            .table-data-wrapper {
                &:after {
                    @extend %player-out;
                }
            }
        }
        .tab-container-wrap {
            .table-responsive {
                .table-head .label {
                    flex-direction: row;
                    @extend %flex-fs-c;
                    &::before {
                        @extend %neutral-50;
                        @extend %mr-1;
                    }
                }
            }
        }
    }
}
.impact-player-container {
    @extend %mb-3;
    @extend %p-2;
    border-bottom: .1rem solid clr(neutral-800, 4);
    .title {
        @extend %font-12-pb;
    }
    .impact-player-name {
        @extend %font-12-pr;
    }
    .team-impact-player-body {
        @extend %d-none;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-cricketscorecard {
        .impact-player-legends {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block: var(--space-4) 0;
            .label-txt {
                font-weight: 700;
                font-size: 1.4rem;
            }
            .label {
                margin-bottom: 0;
            }
        }
        .impact-player-label {
            gap: var(--space-3)
        }
        .scorecard {
            .tab-head {
                gap: 0;
            }
            .head-data {
                .text {
                    font-size: 1.4rem
                }
            }
            .table {
                &-body {
                    .table-data {
                        min-height: 8rem;
                    }
                }
                &-data {
                    width: 7%;
                    &.label {
                        width: 65%;
                        span,
                        .text,
                        .name {
                            font-size: 1.4rem
                        }
                    }
                    &.strike-rate,
                    &.economy-ball {
                        width: 7%;
                    }
                    .text {
                        font-size: 1.4rem;
                    }
                }
            }
            .wickets {
                .table-data {
                    width: 10%;
                    &.label {
                        width: 90%;
                    }
                    &.overs {
                        padding-right: var(--space-3);
                        align-items: flex-end;
                    }
                }
            }
            .extras-scorecard {
                font-size: 1.4rem;
                .label {
                    font-size: 1.4rem
                }
                .extra-info {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .impact-player-container {
        .impact-player-header {
            display: flex;
            gap: var(--space-2);
            .title {
                font-size: 1.4rem
            }
            span {
                font-size: 1.4rem
            }
        }
    }
}