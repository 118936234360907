@use "../config/" as *;
.waf-cricketscorecard {
    .tab-container-wrap.teams {
        .tabs {
            .shortname {
                @extend %d-none;
            }
        }
        .impact-player-legends {
            @extend %mt-2;
        }
        .table {
            @extend %px-2;
            &-team-name {
                @extend %d-none;
                @extend %font-16-pb;
                @extend %ml-3;
                @extend %neutral-700;
                @extend %mb-2;
            }
            &-head {
                @extend %d-none;
            }
            &-left {
                @extend %w-100;
                .text {
                    text-transform: unset;
                    @extend %neutral-800;
                    @extend %font-12-pm;
                    &:not(:first-child) {
                        &::before {
                            content: "|";
                            @extend %mx-1;
                        }
                    }
                }
                .info {
                    @extend %flex;
                    @extend %w-100;
                }
            }
            &-data {
                height: auto;
                @extend %flex-wrap;
                @extend %p-3;
            }
            &-right {
                @extend %d-none;
            }
            &-title {
                @extend %font-16-pb;
                @extend %p-3;
                @extend %neutral-200-bg;
            }
            &-row {
                @extend %my-3;
                @extend %neutral-200-bg;
                @extend %half-radius;
            }
        }
        .player {
            &-img {
                @extend %d-none;
            }
            &-name {
                @extend %w-100;
                @extend %text-left;
                @extend %font-14-pb;
                @extend %mb-2;
            }
        }
        .table-team-wrap {
            display: none;
            &.active {
                display: block;
            }
        }
    }
}
@include mq(col-md) {
    .waf-cricketscorecard {
        .tab-container-wrap.teams {
            margin-top: var(--space-3);
            .impact-player-legends {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
                margin: 0;
            }
            .tab-body {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: var(--space-3);
            }
            .table {
                padding: 0;
                &-title {
                    margin-bottom: var(--space-3);
                }
                &-row {
                    margin-top: 0;
                }
                &-team-name {
                    display: block;
                }
                &-team-wrap:not(:first-child) {
                    .table-title {
                        color: transparent;
                        pointer-events: none;
                    }
                    .table-responsive {
                        border-left: .1rem solid clr(neutral-800, 4);
                    }
                }
                &-responsive {
                    margin-top: -3rem;
                    padding-top: 3rem;
                }
            }
            .table-team-wrap {
                display: block;
                &:nth-child(1) {
                    .table-responsive {
                        padding-right: var(--space-4);
                    }
                    .table-title {
                        border-radius: var(--half-radius) 0 0 var(--half-radius);
                    }
                }
                &:nth-child(2) {
                    .table-responsive {
                        padding-left: var(--space-4);
                    }
                    .table-team-name {
                        margin-left: var(--space-6);
                    }
                    .table-title {
                        border-radius: 0 var(--half-radius) var(--half-radius) 0;
                    }
                }
            }
            .tab-head .tab-section {
                display: none;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-cricketscorecard {
        .tab-container-wrap.teams {
            .table-body {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: var(--space-3);
                padding-inline: var(--space-1);
            }
            .table-row {
                margin: 0;
            }
            .table-wrap {
                margin-bottom: var(--space-3);
            }
        }
    }
}