@use "../config/" as *;
.waf-cricketscorecard {
    .graph-tab-container {
        padding: 0 var(--space-3) var(--space-6) var(--space-3);
    }
    .manhattan-section {
        @extend %pb-16;
        .team-a::after {
            @extend %accent-900-bg;
        }
        .team-b::after {
            @extend %primary-700-bg;
        }
        .inner-wrap {
            height: calc(35rem + var(--space-3));
            @extend %relative;
        }
        .score-line {
            height: calc(35rem + var(--space-3));
            border-left: 0.1rem solid clr(neutral-800, 1);
            border-bottom: 0.1rem solid clr(neutral-800, 1);
            @extend %relative;
            @extend %ml-4;
            @extend %pt-3;
            .score-label {
                @include position(null, null, -2rem, -3.5rem);
                @extend %font-12-pb;
                @extend %neutral-800;
            }
            .line-wrap {
                height: 5rem;
                border-top: 0.1rem dashed clr(neutral-800, 3);
                @extend %flex-n-fs;
                .lable {
                    margin: -0.8rem 0 0 var(--space-4-neg);
                    @extend %font-12-pb;
                    @extend %neutral-800;
                }
            }
        }
        .graph {
            &-listing {
                width: calc(100% - var(--space-8));
                height: calc(100% + var(--space-3));
                @include position(null, null, -3rem, var(--space-8));
            }
            &-wrap {
                overflow: auto;
                @extend %relative;
                @extend %h-100;
                @extend %flex;
            }
            &-item {
                width: 7rem;
                flex-shrink: 0;
                margin: 0 0.5rem;
                padding-bottom: 2.7rem;
                &:first-child {
                    @extend %ml-2;
                }
                .bar-wrap,
                .graph-wrap {
                    overflow: visible;
                    @extend %flex-c-fe;
                    @extend %h-100;
                }
                .bar-graph-line {
                    width: 1.3rem;
                    margin: 0 0.3rem;
                    cursor: pointer;
                    align-self: end;
                    transition: all 1s ease;
                    @extend %relative;
                    @extend %h-100;
                    &::after {
                        content: "";
                        position: absolute;
                        inset: 0;
                        clip-path: polygon(50% 0%, 100% 1rem, 100% 100%, 0 100%, 0% 1rem);
                    }
                }
                .graph-value {
                    background: transparent;
                    @extend %py-1;
                    @extend %w-100;
                    @extend %relative;
                    @extend %font-12-pb;
                    // @extend %neutral-50-bg;
                    @extend %flex-c-c;
                }
                &:nth-child(-n + 3) {
                    .graph-info {
                        @include position(0, auto, null, 2rem);
                        &::before {
                            border-top: 1rem solid transparent;
                            border-bottom: 1rem solid transparent;
                            border-right: 1rem solid clr(neutral, 50);
                            border-left: none;
                            @include position(0, auto, null, -0.7rem);
                        }
                    }
                }
            }
        }
        .over-label {
            bottom: 0;
            @extend %ml-8-neg;
            @extend %font-12-pb;
            @extend %relative;
            @extend %flex-c-c;
            &-text {
                @extend %font-14-pm;
            }
            .glider {
                &-nav {
                    cursor: pointer;
                    @include square(3.5rem);
                    @extend %relative;
                    @extend %flex-c-c;
                    &:before {
                        font-family: $font-icon;
                        font-size: 1.2rem;
                    }
                }
                &-prev {
                    left: -1rem;
                    &:before {
                        content: "\e804";
                    }
                }
                &-next {
                    right: -1rem;
                    left: auto;
                    &:before {
                        content: "\e805";
                    }
                }
            }
        }
        .graph-info {
            width: 16.5rem;
            height: 5.5rem;
            z-index: 2;
            border: 0.1rem solid clr(neutral-800, 5);
            display: none;
            @include position(0, 1rem, null, null);
            @extend %half-radius;
            @extend %relative;
            @extend %flex-column-c-c;
            @extend %neutral-50-bg;
            @extend %text-center;
            &::before {
                content: "";
                width: 0;
                height: 0;
                border-top: 1rem solid transparent;
                border-bottom: 1rem solid transparent;
                border-left: 1rem solid clr(neutral-50);
                @include position(0, -0.7rem, null, null);
            }
            .text {
                padding: 0 var(--space-1);
                line-height: 1.2;
                @extend %font-12-pr;
            }
            span.text {
                width: auto;
                height: auto;
                background: none;
                text-transform: none;
                margin-bottom: 0;
                border-radius: 0;
            }
        }
        .bar-graph-line {
            &:hover {
                .graph-info {
                    @extend %flex;
                }
                .wicket-wrap {
                    .wicket-text {
                        .graph-info {
                            @extend %d-none;
                        }
                    }
                }
            }
        }
        .wicket-wrap {
            height: 10rem;
            margin: var(--space-20-neg) 0 0 0;
            @extend %flex-column-fe-n;
            ;
            @extend %relative;
            &:hover {
                .graph-info {
                    @extend %d-none;
                }
            }
        }
        .wicket-text {
            @extend %mb-1;
            @extend %relative;
            &:hover {
                .graph-info {
                    @extend %flex;
                }
            }
            &-label {
                aspect-ratio: 1/1;
                @include square(1.5rem);
                @extend %uppercase;
                @extend %circle-radius;
                @extend %flex-c-c;
                @extend %neutral-50;
                @extend %error-900-bg;
                @extend %font-8-pb;
            }
        }
    }
    .innings-progression-section {
        .inner-wrap {
            position: relative;
        }
        .score-line {
            .score-label {
                @extend %font-12-pb;
                @include position(null, null, -1rem, 3rem);
            }
            .over-label {
                bottom: 0rem;
                @extend %pos-x-center;
                @extend %font-12-pb;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-scorecard {
        .manhattan-section {
            padding-right: var(--space-5);
            .score-line {
                margin-left: var(--space-14);
                .score-label {
                    font-size: 1.2rem;
                }
            }
            .graph {
                &-listing {
                    width: calc(100% - var(--space-14));
                    top: 2.7rem;
                    left: var(--space-14);
                }
                &-item {
                    margin: 0 2rem;
                    // padding-bottom: var(--space-13);
                    padding-bottom: 4.3rem;
                    &:first-child {
                        margin-left: var(--space-8);
                    }
                }
                &-wrap {
                    overflow: hidden;
                }
            }
            .over-label {
                bottom: var(--space-4);
            }
        }
        .innings-progression-section {
            .score-line {
                .score-label {
                    bottom: 1.4rem;
                    left: -1rem;
                }
                .over-label {
                    bottom: -1rem;
                }
            }
        }
    }
}