@use "../config/" as *;
.tabs {
    overflow: auto;
    @extend %neutral-50-bg;
    @extend %w-100;
    @extend %flex;
    &.primary-tabs {
        @extend %half-radius;
        @extend %w-100;
    }
    &.secondary-tabs {
        @extend %gap-2;
        .tab-name {
            border: .1rem solid currentColor;
            background: unset;
            @extend %half-radius;
            @extend %neutral-600;
        }
        .tab-active {
            color: var(--primary-700)
        }
    }
    .tab-name {
        flex: 1;
        min-width: max-content;
        cursor: pointer;
        @extend %text-center;
        @extend %p-3;
        @extend %neutral-800-7;
        @extend %font-14-pm;
    }
    .tab-active,
    .active {
        font-weight: 700;
        @extend %neutral-800;
        @extend %tertiary-600-bg;
    }
}
.graphs-tab {
    .tabs {
        @extend %flex-c-c;
        @extend %gap-4;
    }
    .tab-name {
        border: .1rem solid clr(neutral-800, 4);
        width: max-content;
        height: auto;
        flex: unset;
        padding: 0 var(--space-2) 0 0;
        font-size: 1.2rem;
        min-height: 3.5rem;
        @extend %gap-1;
        @extend %flex-n-c;
        @extend %rounded-radius;
        .fullname {
            @extend %d-none;
        }
        .name {
            font-size: inherit;
            font-weight: 600;
        }
        .score,
        .over {
            font-size: inherit;
        }
        .team-img {
            width: 3.5rem;
            height: 3.5rem;
            flex-shrink: 0;
            border: .1rem solid clr(neutral-800, 3);
            @extend %ml-2-neg;
        }
        &.active,
        &.tab-active {
            border-color: transparent;
            @extend %accent-900-bg;
            @extend %neutral-50;
        }
        .team-info {
            width: max-content
        }
    }
}
.tab-container {
    @extend %neutral-5-bg;
    @extend %p-2;
    &-wrap {
        @extend %pure-white-900-bg;
        @extend %half-radius;
        @extend %hidden;
        .tab-container {
            @extend %p-0;
            @extend %pure-white-900-bg;
        }
    }
    .tab-section {
        @extend %p-2;
    }
}
.detail-info-container {
    margin: auto;
}
.tab-legends {
    @extend %flex-c-n;
    @extend %gap-2;
    .legend-item {
        border: .1rem solid clr(neutral-800, 4);
        width: max-content;
        height: auto;
        flex: unset;
        min-height: 3.5rem;
        padding: 0 var(--space-2) 0 0;
        font-size: 1.2rem;
        @extend %gap-1;
        @extend %flex-n-c;
        @extend %rounded-radius;
        &.team-a {
            @extend %accent-900-bg;
        }
        &.team-b {
            flex-direction: row;
            @extend %primary-700-bg;
        }
        .team-img {
            width: 3.5rem;
            height: 3.5rem;
            flex-shrink: 0;
            border: .1rem solid clr(neutral-800, 3);
            @extend %ml-2-neg;
        }
        .name {
            @extend %neutral-50;
        }
    }
    .fullname {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .graphs-tab {
        .tabs {
            gap: var(--space-6);
        }
        .team-name {
            font-size: 1.4rem;
        }
    }
}
@include mq(col-md) {
    .detail-info-container {
        padding-inline: calc(var(--container-white-space) + 5%);
    }
}