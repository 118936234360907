@use "../config/" as *;
.commentary {
    .shortname {
        @extend %d-none;
    }
    .waf-select-box {
        @extend %d-none;
    }
    .title {
        @extend %font-26-pb;
        @extend %my-4;
    }
    .sub-title,
    .highlight-title {
        @extend %font-18-pb;
        @extend %my-4;
    }
    .para-text,
    p {
        @extend %font-12-pr;
        @extend %mb-2;
    }
    .over-poll-section,
    .rpm-over {
        @extend %d-none;
    }
    .over-info {
        .head {
            @extend %p-2-3;
            @extend %mb-1;
            @extend %font-14-pb;
            @extend %neutral-50;
            @extend %primary-900-bg;
            .match-info {
                @extend %gap-2;
            }
        }
        .head-wrap {
            @extend %uppercase;
            @extend %flex-sb-c;
            .over-info {
                @extend %flex-n-c;
                @extend %gap-2;
            }
        }
        .over-item {
            @extend %p-3;
            @extend %relative;
            @extend %neutral-5-bg;
            @extend %mb-2;
            @extend %half-radius;
        }
        .over-text {
            &:not(:first-child) {
                border-left: .1rem solid clr(neutral-50, 5);
                @extend %pl-2;
            }
        }
        .over-details {
            width: 6rem;
            flex-shrink: 0;
        }
        .body {
            .score {
                border: 0.1rem solid clr(neutral-800, 5);
                width: max-content;
                height: 3.4rem;
                min-width: 4.2rem;
                @extend %flex-c-c;
                @extend %mx-auto;
                @extend %font-14-pm;
                @extend %half-radius;
                @extend %px-1;
                @extend %neutral-200-bg;
            }
            .four .score {
                border: 0;
                @extend %tertiary-800-bg;
            }
            .six .score {
                border: 0;
                @extend %tertiary-800-bg;
            }
            .wicket .score {
                border: 0;
                @extend %accent-900-bg;
                @extend %neutral-50;
            }
        }
        .over-wrap {
            @extend %gap-3;
            @extend %flex-n-c;
        }
    }
    .over-count {
        @extend %text-center;
        @extend %relative;
        @extend %font-12-pr;
        @extend %d-block;
        @extend %mt-1;
    }
    &-title {
        @extend %font-14-pb;
        @extend %neutral-800;
        @extend %mb-2;
        .over-count {
            display: none;
        }
    }
    &-text {
        line-height: 2.5rem;
        @extend %font-14-pr;
        @extend %neutral-600;
    }
    .over-commentary {
        border-left: .1rem solid var(--neutral-400);
        @extend %pl-4;
    }
    .loadmore {
        @extend %w-100;
        &-wrap {
            @extend %w-100;
        }
    }
    .tab-body {
        .tab-container {
            @extend %px-2;
        }
    }
}
@include mq(col-md) {
    .commentary {
        .loadmore-wrap {
            width: max-content
        }
    }
}